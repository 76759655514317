body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;
}

html,
body,
#root {
  height: 100%;
  background-color: #FFF;
}

/*  */
/* inputs */
/*  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.app-wrapper {
  position: relative;
  height: calc(100% - 110px);
}

.app-banner {
  border-bottom: 1px solid #e5e5e5;
  background-color: #fff;
  height: 110px;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-banner img {
  height: 60px;
  user-select: none;
}

.hfb-logo {
  display: flex;
  flex: 1 1 25%;
  justify-content: center;
  align-items: center;
}

.sponsor-logo-wrapper {
  display: flex;
  flex: 1 1 50%;
  align-items: center;
  justify-content: space-evenly;
}

.action-wrapper {
  display: flex;
  flex: 1 1 25%;
  align-items: center;
  justify-content: space-evenly;
  min-width: 150px;
}

@media screen and (max-width: 749px) {
  .app-wrapper {
    position: relative;
    height: calc(100% - 90px);
  }
  .app-banner {
    height: 90px;
    flex-wrap: wrap;
  }
  .app-banner img {
    height: 30px;
  }
  .sponsor-logo-wrapper {
    flex: 1 1 75%;
    justify-content: space-around;
  }
}

iframe.goog-te-banner-frame,
.goog-tooltip {
  display: none !important;
}

#google_translate_element {
  position: absolute !important;
  top: 15px !important;
  left: 20px !important;
  z-index: 1 !important;
}

body {
  top: 0 !important;
}

#map-container {
  height: 100%;
  width: 100%;
  position: absolute;
}

.info-title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}

.info-sub-title {
  font-weight: 600;
}

.info-address,
.info-phone,
.info-times {
  font-size: 13px;
  color: #000;
}

.info-address,
.info-phone {
  display: inline-block;
  text-decoration: underline !important;
}

.info-address:nth-of-type(2),
.info-phone,
.info-times {
  margin-bottom: 10px;
}

#register-btn {
  background: transparent;
  color: #76b900;
  border: 1px solid rgba(118, 185, 0, 0.5);
  padding: 4px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  outline: none;
}

#register-btn:hover {
  border: 1px solid #76b900;
  background-color: rgba(118, 185, 0, 0.04);
}

.pantry-info-title {
  font-size: 16px;
  font-weight: 500;
}

.pantry-info-wrapper {
  display: flex;
  flex-direction: column;
}

.pantry-info {
  display: flex;
}

.pantry-info div:first-of-type {
  font-weight: 500;
}

.search-wrapper {
  position: absolute;
  top: 40px;
  left: calc(50% - 215px);
  display: flex;
  align-items: center;  
}

.search-input-wrapper {
  display: flex;
  width: 380px;
}

.search-input {
  flex: 1;
  margin-left: 16px;
}

#search-input-base {
  height: 35px;
  width: calc(100% + 54px);
  margin-left: -16px;
  margin-right: -48px;
  padding-left: 10px;
  padding-right: 48px;
}

@media screen and (max-width: 599px), screen and (max-height: 599px) {
  .search-wrapper {
    top: 30px;
    left: 3%;
    right: 3%;
    width: 94%;
  }
  .search-input-wrapper {
    width: 100%;
  }
  #google_translate_element {
    top: 0px !important;
    left: 0px !important;
  }
}

/* google places dropdown */
.pac-container {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.reset-button {
  margin: 8px 0px !important;
  background-color: #FFF !important;
  position: absolute !important;
  top: 60px;
  left: calc(50% - 45px);
}

.cluster span {
  color: #FFF;
}

.info-not-available {
  margin-bottom: 5px;
}

.info-dialog-section {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 8px 0;
}

.info-icon-container {
  width: 36px;
}

.info-dialog-register-list {
  margin: 0;
  padding: 0 0 0 20px;
  list-style: none;
}

.info-dialog-register-list li {
  margin: 8px 0;
  padding: 0;

}

.info-dialog-qa-wrapper {
  padding-left: 20px;
  margin: 8px 0;
}

.info-dialog-contact-wrapper {
  padding-left: 20px;
}

.help-icon {
  height: 100% !important;
  width: 100% !important;
  font-size: 26px !important;
  background-color: #000 !important;
  border-radius: 100vw !important;
}

/* The popup bubble styling. */
.popup-bubble {
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: #9b9b9b;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  opacity: .9;
}

.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
}

.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-60%, 0);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #9b9b9b;
  opacity: .9;
}

.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  width: 200px;
  transform: translate3d(0,0,0);
}

.register-print-info {
  display: none;
}

@media print {
  header,
  footer,
  #map-container,
  .register-info {
    display: none !important;
  }

  .register-print-info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
}